<template>
  <div>
    <v-row class="mb-4">
      <v-col>
        <h2 v-if="isAddEvent">Add Event</h2>
        <h2 v-else>Edit Event</h2>
      </v-col>
    </v-row>
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-form ref="event_detail_form">
        <v-row v-if="!isAddEvent">
          <v-col class="d-flex align-center justify-space-between">
            <h3>Latest update: {{ event_detail_data.create_date }}</h3>
            <!--  <v-btn v-if="!isAddEvent" @click="copySharedLink" color="info">分享連結</v-btn> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col  class="xs-full">
            <v-text-field
              type="text"
              label="Event Name"
              outlined
              hide-details="auto"
              v-model="event_detail_data.name"
              :rules="[Validator.required]"
            >
            </v-text-field>
          </v-col>
          <v-col class="xs-full">
            <v-text-field
              type="text"
              label="company"
              outlined
              hide-details="auto"
              v-model="event_detail_data.company"
              :rules="[Validator.required]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="xs-full">
            <v-menu
              ref="meeting_date_menu"
              v-model="meeting_date_menu"
              :close-on-content-click="false"
              :return-value.sync="meeting_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="meeting_date"
                  label="Event Date"
                  prepend-icon=""
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :rules="[Validator.required]"
                  hide-details="auto"
                ></v-text-field>
              </template>
              <v-date-picker v-model="meeting_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="meeting_date_menu = false"> 取消 </v-btn>
                <v-btn text color="primary" @click="$refs.meeting_date_menu.save(meeting_date)"> 確定 </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col xl="3" lg="3" md="3" sm="12" col="12" class="xs-full">
            <v-menu
              ref="meeting_time_menu"
              v-model="meeting_time_menu"
              :close-on-content-click="false"
              :return-value.sync="meeting_time"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="meeting_time"
                  label="Start"
                  prepend-icon=""
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  type="text"
                  :rules="[Validator.required]"
                  hide-details="auto"
                >
                </v-text-field>
              </template>
              <v-card class="py-2 px-2">
                <div>
                  <v-select
                    label="時"
                    :items="hour_list"
                    hide-details="auto"
                    v-model="hour"
                    :error="time_error"
                  ></v-select>
                  <v-select
                    label="分"
                    :items="min_list"
                    hide-details="auto"
                    v-model="min"
                    :error="time_error"
                  ></v-select>
                </div>
                <v-spacer class="mt-2"></v-spacer>
                <v-btn text color="primary" @click="meeting_time_menu = false"> 取消 </v-btn>
                <v-btn text color="primary" @click="saveTime"> 確定 </v-btn>
              </v-card>
            </v-menu>
          </v-col>
          <v-col xl="3" lg="3" md="3" sm="12" col="12" class="xs-full">
            <v-menu
              ref="meeting_time_menu_end"
              v-model="meeting_time_menu_end"
              :close-on-content-click="false"
              :return-value.sync="meeting_time_end"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="meeting_time_end"
                  label="End"
                  prepend-icon=""
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  type="text"
                  :rules="[Validator.required]"
                  hide-details="auto"
                >
                </v-text-field>
              </template>
              <v-card class="py-2 px-2">
                <div>
                  <v-select
                    label="時"
                    :items="hour_list"
                    hide-details="auto"
                    v-model="hour_end"
                    :error="time_error"
                  ></v-select>
                  <v-select
                    label="分"
                    :items="min_list"
                    hide-details="auto"
                    v-model="min_end"
                    :error="time_error"
                  ></v-select>
                </div>
                <v-spacer class="mt-2"></v-spacer>
                <v-btn text color="primary" @click="meeting_time_menu_end = false"> 取消 </v-btn>
                <v-btn text color="primary" @click="saveTimeEnd"> 確定 </v-btn>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              type="text"
              label="Location"
              outlined
              hide-details="auto"
              v-model="event_detail_data.location"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-select
                label="Performer"
                :items="performer_list"
                item-text="item-text"
                item-value="item-value"
                hide-details="auto"
                outlined
                multiple
                v-model="event_detail_data.performers"
                ></v-select>
            </v-col>
            <v-col>
                <v-text-field
                    type="text"
                    label="Quota"
                    outlined
                    hide-details="auto"
                    v-model="event_detail_data.capacity"
                    :rules="[Validator.required,Validator.isPositive,Validator.isNumberOnly]"
                    >
                </v-text-field>
            </v-col>
        </v-row>
         <v-row>
            <v-col>
                <v-text-field
                    type="text"
                    label="Price"
                    outlined
                    hide-details="auto"
                    v-model="event_detail_data.ticket_price"
                    :rules="[Validator.required,Validator.isPositive,Validator.isNumberOnly]"
                    prefix="$"
                    >
                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field
                    type="text"
                    label="Earn Credit"
                    outlined
                    hide-details="auto"
                    v-model="event_detail_data.credit"
                    :rules="[Validator.required,Validator.isPositive,Validator.isNumberOnly]"
                    >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="12" col="6" class="xs-full">
                <v-text-field
                    type="text"
                    label="Inventory"
                    outlined
                    hide-details="auto"
                    v-model="event_detail_data.inventory"
                    :rules="[Validator.required,Validator.isPositive,Validator.isNumberOnly]"
                    >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
                label="Description"
                height="200"
                outlined
                hide-details="auto"
                v-model="event_detail_data.description"
            >
            </v-textarea>
          </v-col>
        </v-row>
        
        <v-row class="mt-8">
          <v-col>
            <h3>Cover Photo</h3>
          </v-col>
        </v-row>

        <v-row>
            <v-col xl="10" lg="10" md="10" sm="10" col="12">
                <v-img
                    width="100"
                    height="100"
                    :src="cover"
                >
                </v-img>
                <v-file-input
                    id="cover_image"
                    v-show="false"
                    accept="image/*"
                    @change="getEventImageFile($event)"
                ></v-file-input>
            </v-col>
            <v-col class="text-right">
                <v-btn color="primary" width="110" @click="triggerUploadImage('cover_image')" class="ml-3" :loading="submitLoading"
                >UPLOAD</v-btn>
                <!-- <v-btn color="primary" width="110" @click="previewImage('preview')" class="ml-3 mt-1" :loading="submitLoading"
                >PREVIEW</v-btn> -->
            </v-col>
        </v-row>
        
        <v-row class="mt-8">
          <v-col>
            <h3>Album</h3>
          </v-col>
        </v-row>

        <v-row>
            <v-col xl="10" lg="10" md="10" sm="10" col="12" class="d-flex flex-wrap">                
              <p v-for="(album_cover, index) in album" :key="album_cover" class="mr-1 text-center">
                <v-img 
                    width="100"
                    height="100"
                    :src="album_cover"
                >
                </v-img><br>
                <v-icon aria-hidden="false" class="cursor-pointer mr-1" @click="editAlbum(index)">
                  {{ icons.mdiImageEdit }}
                </v-icon>
                <v-icon aria-hidden="false" class="cursor-pointer" @click="deleteAlbum(index)">
                  {{ icons.mdiDeleteCircle }}
                </v-icon>
              </p>
            </v-col>
            <v-col class="text-right">
                
                <v-btn color="primary" width="110" @click="addAlbum()" class="ml-3 mt-1" :loading="submitLoading"
                >ADD ALBUM</v-btn>
            </v-col>
        </v-row>
        
        <v-row class="mt-8">
          <v-col class="d-flex align-center">
            <v-btn color="success" @click="saveEvent" :loading="submitLoading">儲存</v-btn>
            <v-btn v-if="!isAddEvent" color="error" class="ml-3" @click="removeEventData">刪除</v-btn>
          </v-col>
        </v-row>

        <v-row class="mb-8">
          <v-col>
            <v-tabs v-model="tab">
              <v-tab key="order">Latest Sales</v-tab>
              <v-tab key="attendant">Attendant</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item key="order">
                <table cellpadding="10" cellspacing="20">
                  <tr><td>Sales Amount</td><td>$0</td></tr>
                  <tr><td>Sales Tickets</td><td>0</td></tr>
                </table>
                <data-table-with-pagination
                  :headers="order_header"
                  :dataList="order_list"
                ></data-table-with-pagination>
              </v-tab-item>
              <v-tab-item key="attendant">
                <data-table-with-pagination
                  :headers="order_header"
                  :dataList="order_list"
                ></data-table-with-pagination>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    
    <Dialog :dialog="dialog.open" :text="dialog.text" :isDeleteDialog="dialog.isDelete" v-on:close="dialogClose" :max_width="dialog.max_width" :loading="submitLoading">
      <div class="mt-4" v-if="dialog.type=='add_album' || dialog.type=='edit_album'">
        <v-row> 
          <v-col class="text-right">
            <v-btn color="primary" width="110" @click="triggerUploadImage('album_image')" class="ml-3" :loading="submitLoading"
                >UPLOAD</v-btn>
            <v-file-input
                id="album_image"
                v-show="false"
                accept="image/*"
                @change="getEventImageFileAlbum($event)"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row> 
          <v-col class="d-flex flex-wrap">
            <p v-for="(temp_p, index) in photos" :key="temp_p" class="mr-1 text-center">
                <v-img 
                    width="100"
                    height="100"
                    :src="temp_p"
                >
                </v-img><br>
                <v-icon aria-hidden="false" class="cursor-pointer" @click="deletePhoto(index)">
                  {{ icons.mdiDeleteCircle }}
                </v-icon>
            </p>
          </v-col>
        </v-row>
      </div>
    </Dialog>
    <LoadingDialog :load="showLoadingPage"></LoadingDialog>
    <AlertBox :type="alertBox.type" :text="alertBox.text" :show="alertBox.show"></AlertBox>
  </div>
</template>

<script>
import { Validator, globalFunc, MEDIA_PATH } from '@/global'
import QuillEditor from '@/components/QuillEditor'
import Dialog from '@/components/Dialog'
import LoadingDialog from '@/components/LoadingDialog'
import AlertBox from '@/components/AlertBox'
import DataTableWithPagination from '@/components/DataTableWithPagination.vue'
import { getData, postData } from '@/api'
import { mapActions } from 'vuex'
import VueTimepicker from 'vue2-timepicker'
import { mdiDeleteCircle, mdiImageEdit } from '@mdi/js';

export default {
  name: 'event-detail',
  components: {
    QuillEditor,
    Dialog,
    LoadingDialog,
    AlertBox,
    DataTableWithPagination,
    VueTimepicker,
  },
  data: () => ({
    verifiedError: false,
    Validator: Validator,
    isAddEvent: true,
    showLoadingPage: false,
    submitLoading: false,
    temp_p: null,
    tab: null,
    icons: { mdiDeleteCircle: mdiDeleteCircle, mdiImageEdit:mdiImageEdit },
    album: [
      require('@/assets/images/images/no_image.png')
    ],
    album_cover: null,
    image_update: false,
    dialog: {
      isDelete: true,
      open: false,
      text: '刪除相簿',
      index: -1,
      type: '',
      max_width: 1500,
    },
    cover: require('@/assets/images/images/no_image.png'),
    order_header: [
      { text: 'Date', value: 'date' },
      { text: 'Order Number', value: 'id' },
      { text: 'Tickets Number', value: 'ticket_id' },
      { text: 'Amount', value: 'amount' },
      { text: 'Status', value: 'status' }
    ],
    order_list: [],
    photos: [
        require('@/assets/images/images/no_image.png'),
    ],
    // album_list: [
    //   { id:null, images:[] }
    // ],
    album_list: [],
    delete_album_list:[],
    album_index: null,
    album_null: true,
    album_init: true,
    album_pic_null: true,
    alertBox: {
      show: false,
      text: '',
      type: '',
    },
    dialog: {
      isDelete: true,
      open: false,
      text: '刪除圖片',
      index: -1,
      type: '',
    },
    event_detail_data: {
      create_date: '',
      name: '',
      company: '',
      meeting_date_menu: '',
      meeting_time_menu: '',
      meeting_time_menu_end: '',
      location: '',
      ticket_price: '',
      credit: '',
      performer: [],
      description: '',
    },
    backup_event_detail_data: {
      create_date: '',
      name: '',
      company: '',
      meeting_date_menu: '',
      meeting_time_menu: '',
      meeting_time_menu_end: '',
      location: '',
      ticket_price: '',
      credit: '',
      performer: [],
      description: '',
    },
    meeting_date: null,
    meeting_date_menu: null,
    meeting_time: null,
    meeting_time_menu: null,
    meeting_time_end: null,
    meeting_time_menu_end: null,
    hour_list: ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
    hour_list_end: ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
    min_list: ["00","15","30","45"],
    min_list_end: ["00","15","30","45"],
    time_error: null,
    hour: null,
    min: null,
    hour_end: null,
    min_end: null,
    performer_list: [],
    backup_performer_list: [],

  }),
  methods: {
    ...mapActions(['reset']),
    /**
     * Delete the whole album
     * @param {number} - The index of the album
     */
    deleteAlbum(index) {
      this.delete_album_list.push(this.album_list[index].id)
      this.album.splice(index,1)
      this.album_list.splice(index,1)
      
      this.image_update = true
    },
    /**
     * Delete the photo from album dialog
     */
    deletePhoto(index) {
      this.photos.splice(index,1)
      this.image_update = true
    },
    /**
     * The dialog of click the add album button
     */
    addAlbum() {
      this.dialog.open = true
      this.dialog.text = "新增相簿"
      this.dialog.isDelete = false
      this.dialog.type = "add_album"
      this.dialog.max_width = 1500

      this.photos= [ require('@/assets/images/images/no_image.png') ]

      this.album_null = true
    },
    /**
     * The dialog of clicking edit icon which below the Album photo
     * @param {number} - the index of the album
     */
    editAlbum(index) {
      this.dialog.open = true
      this.dialog.text = "修改相簿"
      this.dialog.isDelete = false
      this.dialog.type = "edit_album"
      this.dialog.max_width = 1500

      this.photos= [ require('@/assets/images/images/no_image.png') ]
      this.album_null = false
      console.log('--close--')
      console.log(this.album_list.length)
      if(this.album_list.length) {
        if(this.album_list[index].images) {
          this.photos=[]
          for(let i=0; i<this.album_list[index].images.length;i++) {
            this.photos.push(this.album_list[index].images[i])
          }
        }
      }

      this.album_index=index
    },
    /**
     * The action of user clicking the dialog close button
     * @param {{action}} - provide by dialog component
     */
    dialogClose(action) {
      if(action) {
        if(this.dialog.type=="edit_album") {  
          if(!this.album_list.length) {
            this.album_list.push({id:null,images:[]})
          }
          this.album_list[this.album_index].images=[]   
          for(let i=0;i<this.photos.length;i++) {
            this.album_list[this.album_index].images.push(this.photos[i])
          }
          if(this.album_pic_null) {
            this.album.pop();
            this.album_pic_null=false
          }
          this.album[this.album_index]=this.photos[0]
        }
        if(this.dialog.type=="add_album") {     
          this.album_list.push({id:null,images:[],update:false})
          for(let i=0;i<this.photos.length;i++) {
            this.album_list[this.album_list.length-1].images.push(this.photos[i])
          }
          if(this.album_pic_null) {
            this.album.pop();
            this.album_pic_null=false
          }
          this.album.push(this.photos[0])
        }
        if(this.dialog.type=="delete_event") {
          this.deleteEvent()
        }
      }
      
      this.dialog.open = false
    },
    /**
     * Trigger the hidden file input
     * @param {string} - the selector of the file input field
    */
    triggerUploadImage(id) {
      document.getElementById(id).click()
    },
    /**
     * Initializing the data
    */
    async initializeData() {
      try {
        let params = new URLSearchParams()
        params.set(
          'get_event_by_id',
          JSON.stringify({
            id: this.$route.params.id,
          }),
        )
        let result = await getData(params)

        if (!Array.isArray(result)) {
          let data = result.event

          console.log('--- Get event data ---')
          console.log(result)

          let temp_data = {
            create_date: globalFunc.getDateStr(data.create_date),
            name: data.name,
            company: data.company,
            date: data.date,
            start_time: data.start_time,
            end_time: data.end_time,
            location: data.location,
            performers: data.performers,
            capacity: data.capacity,
            ticket_price: data.ticket_price,
            credit: data.credit,
            description: data.description,
            inventory:data.inventory
          }


          this.cover = data.splash,

          this.meeting_date = globalFunc.getDateStr(data.date)
          this.backup_meeting_date = this.meeting_date
          this.meeting_time = data.start_time
          this.backup_meeting_time = this.meeting_time
          this.meeting_time_end = data.end_time
          this.backup_meeting_time_end = this.meeting_time_end

          let hour_min = this.meeting_time.split(":");
          this.hour = hour_min[0];
          this.min = hour_min[1];

          let hour_min_end = this.meeting_time_end.split(":");
          this.hour_end = hour_min_end[0];
          this.min_end = hour_min_end[1];

          let params2 = new URLSearchParams()

          params2.set(
            'get_album_by_event_id',
            JSON.stringify({
              id: parseInt(this.$route.params.id),
            }),
          )

          let result2 = await getData(params2)

          console.log('--- Get album data ---')
          console.log(result2)

          let data2 = result2

          if(data2){
            
            
            for(let i=0, n=0; i < data2.length ; i++) {
              if(i==0) { this.album = [] }
              if(data2[i].active){
                if(data2[i].images) {
                  this.album_init=false
                  this.album_pic_null=false
                  this.album.push(data2[i].images[0])
                  this.album_list.push({id:data2[i].id,images:[],update:true})
                  for(let j=0; j < data2[i].images.length; j++) {
                    this.album_list[n].images.push( data2[i].images[j] )
                  }
                } else {
                  this.album_pic_null=true
                  this.album.push(require('@/assets/images/images/no_image.png'))
                }
                
                n++
              }
            }
          }

          console.log("--album-list--")
          console.log(this.album_list)

          // for (let i = 0; i < data.images.length; i++) {
          //   this.addCoverImage(null, data.images[i])
          // }

          this.backup_event_cover_image_list = Object.assign([], this.event_cover_image_list)

          Object.assign(this.event_detail_data, temp_data)
          Object.assign(this.backup_event_detail_data, temp_data)
        }
      } catch (error) {
        console.log('--- Get event data fail ---')
        console.log(error)
      }
    },
    /**
     * Getting the performer data
    */
    async getPerformerData() {
      
      try {
        let params = new URLSearchParams()
        params.set('get_all_performer', 
          JSON.stringify({
            id: this.$route.params.id,
          }))

        let result = await getData(params)
        console.log('--- Get performer data ---')
        console.log(result)

        if (result) {
          let data = result

          let temp = new Array()

          for (let i = 0; i < data.length; i++) {
            temp.push({
              'item-text': data[i].name,
              'item-value': data[i].id,
            })
          }
          this.performer_list = temp
          this.backup_performer_list = temp
        }
      } catch (error) {
        console.log('--- Get performer data fail ---')
        console.log(error)
      }
    },
    /**
     * Initializing album
    */
    async initializeAlbum() {
      
      this.load_album_table = true
      try {
        let params = new URLSearchParams()
        params.set(
          'get_all_album',
          JSON.stringify({
          }),
        )
        let result = await getData(params)
        console.log('--- Get all Album data ---')
        console.log(result)
      } catch(error) {
        
      }
    },
    /**
     * uploading a cover image
     * @param {string} image_name - the name of the image
    */
    addCoverImage(image_name = null) {
      let data = {
        image: '',
        image_file: {},
        preview_image: '',
        hidePreview: true,
      }

      if (image_name != null) {
        data.image = image_name
        data.preview_image = MEDIA_PATH + image_name
        data.hidePreview = false
      }

      this.event_cover_image_list.push(data)
      console.log('----event_cover_image_list') 
      console.log(this.event_cover_image_list)
    },
    /**
     * Trigging the file input
     */
    triggerUploadEventImage(type, index = -1) {
      if (type == 'event') {
        document.getElementById(`event_image_${index}`).click()
      } else {
        document.getElementById('preview_image').click()
      }
    },
    /**
     * uploading an event image
     * @param {file} file - the file, selected by the input
     * @param {string} type - uploading type
     * @param {file} file - the index of the image
    */
    async getEventImageFile(file) {
      if (globalFunc.DataValid(file)) {
        const vm = this
        if (file) {
          try {
            let bas4Path = await globalFunc.firstFileToBase64(file)

            let type = file.type.substring(file.type.lastIndexOf('/') + 1)
            let params = new URLSearchParams()
            let send_data = {
              upload_data: bas4Path,
              upload_file_type: type,
            }
            
            params.set('upload_file', JSON.stringify(send_data))

            const config = {
              onUploadProgress: progressEvent => {
                console.log(progressEvent.loaded)
                vm.progress = progressEvent.loaded * 0.75
              },
            }

            let upload_image = await postData(params, config)
            this.cover = MEDIA_PATH + upload_image;
            this.image_update = true
            vm.uploading = false
            vm.progress = 100
          } catch (error) {
            console.log(error)
            vm.uploading = false
          }
        }
      }
      
    },
    /**
     * Uploading the album images
     * @param {file} - the file which is triggered by upload button inside the dialog box
     */
    async getEventImageFileAlbum(file) {
      if (globalFunc.DataValid(file)) {
        const vm = this
        if (file) {
          try {
            let bas4Path = await globalFunc.firstFileToBase64(file)

            let type = file.type.substring(file.type.lastIndexOf('/') + 1)
            let params = new URLSearchParams()
            let send_data = {
              upload_data: bas4Path,
              upload_file_type: type,
            }

            params.set('upload_file', JSON.stringify(send_data))

            const config = {
              onUploadProgress: progressEvent => {
                console.log(progressEvent.loaded)
                vm.progress = progressEvent.loaded * 0.75
              },
            }

            let upload_image = await postData(params, config)

            if(this.album_null || this.album_init) {
              this.photos.pop()
              this.album_null = false
              this.album_init = false
            }
            this.photos.push(MEDIA_PATH + upload_image)
            this.image_update = true
            vm.uploading = false
            vm.progress = 100
          } catch (error) {
            console.log(error)
            vm.uploading = false
          }
        }
      }
    },
    /**
     * remove event dialog
    */
    removeEventData() {
      this.dialog.text = '刪除活動'
      this.dialog.type = 'delete_event'
      this.dialog.open = true
    },
    /**
     * delete a event
     * @param {string} action - given by the dialog component
    */
    async deleteEvent() {
      try {
        let params = new URLSearchParams()
        params.set(
          'update_event',
          JSON.stringify({
            id: parseInt(this.$route.params.id),
            active: false,
          }),
        )
        let result = await getData(params)
        this.alertBox.text = '刪除成功'
        this.alertBox.type = 'success'
        this.alertBox.show = true
        setTimeout(() => {
          this.$router.replace({ name: 'event-management' })
        }, 1500)
      } catch (error) {
        console.log(error)
        this.alertBox.text = '刪除失敗'
        this.alertBox.type = 'error'
        this.alertBox.show = true
      } finally {
        setTimeout(() => {
          this.submitLoading = false
          this.alertBox.show = false
          this.submitLoading = false
        }, 1500)
      }

      // if (action) {
      //   if (this.dialog.type == 'image') {
      //     this.event_cover_image_list.splice(this.dialog.index, 1)
      //   } else if (this.dialog.type == 'preview') {
      //     this.preview_image_data.image = ''
      //     this.preview_image_data.preview_image = ''
      //     this.preview_image_data.hidePreview = true
      //     this.preview_image_data.image_file = {}
      //   } else {
      //     this.submitLoading = true
      //     try {
      //       let params = new URLSearchParams()
      //       params.set(
      //         'update_event',
      //         JSON.stringify({
      //           id: paresInt(this.$route.params.id),
      //           active: false,
      //         }),
      //       )
      //       let result = await getData(params)
      //       this.alertBox.text = '刪除成功'
      //       this.alertBox.type = 'success'
      //       this.alertBox.show = true
      //       setTimeout(() => {
      //         this.$router.replace({ name: 'event' })
      //       }, 1500)
      //     } catch (error) {
      //       console.log(error)
      //       this.alertBox.text = '刪除失敗'
      //       this.alertBox.type = 'error'
      //       this.alertBox.show = true
      //     } finally {
      //       setTimeout(() => {
      //         this.submitLoading = false
      //         this.alertBox.show = false
      //         this.submitLoading = false
      //       }, 1500)
      //     }
      //   }
      // }
    },
    /**
     * checking and saving data to backend
    */
    async saveEvent() {
      if (!this.$refs.event_detail_form.validate()) {
        return
      }

      this.submitLoading = true

      let key = ''
      let send_data = {};
      let send_data_album = {}

      if (this.isAddEvent) {
        key = 'new_event'
        console.log(this.event_detail_data)
        send_data.name = this.event_detail_data.name
        send_data.company = this.event_detail_data.company
        send_data.date = this.meeting_date
        send_data.start_time = this.meeting_time
        send_data.end_time = this.meeting_time_end
        send_data.location = this.event_detail_data.location
        send_data.performers = this.event_detail_data.performers
        send_data.capacity = parseInt(this.event_detail_data.capacity)
        send_data.ticket_price = parseInt(this.event_detail_data.ticket_price)
        send_data.credit = parseInt(this.event_detail_data.credit)
        send_data.description = this.event_detail_data.description
        send_data.inventory = this.event_detail_data.inventory
        send_data.splash = this.cover

        console.log('----send data-----')
        console.log(send_data)

      } else {
        key = 'update_event'
        send_data.id = parseInt(this.$route.params.id)

        let update = false
        if (this.event_detail_data.name != this.backup_event_detail_data.name) {
          send_data.name = this.event_detail_data.name
          update = true
        }

        if (this.event_detail_data.company != this.backup_event_detail_data.company) {
          send_data.company = this.event_detail_data.company
          update = true
        }

        if (this.meeting_date != this.backup_event_detail_data.date) {
          send_data.date = this.meeting_date
          update = true
        }

        if (this.meeting_time != this.backup_event_detail_data.start_time) {
          send_data.start_time = this.meeting_time
          update = true
        }

        if (this.meeting_time_end != this.backup_event_detail_data.end_time) {
          send_data.end_time = this.meeting_time_end
          update = true
        }

        if (this.event_detail_data.location != this.backup_event_detail_data.location) {
          send_data.location = this.event_detail_data.location
          update = true
        }

        if (this.event_detail_data.performers != this.backup_event_detail_data.performers) {
          send_data.performers = this.event_detail_data.performers
          update = true
        }

        if (this.event_detail_data.inventory != this.backup_event_detail_data.inventory) {
          send_data.inventory = this.event_detail_data.inventory
          update = true
        }

        if (this.event_detail_data.capacity != this.backup_event_detail_data.capacity) {
          send_data.capacity = this.event_detail_data.capacity
          update = true
        }

        if (this.event_detail_data.ticket_price != this.backup_event_detail_data.ticket_price) {
          send_data.ticket_price = this.event_detail_data.ticket_price
          update = true
        }

        if (this.event_detail_data.credit != this.backup_event_detail_data.credit) {
          send_data.credit = this.event_detail_data.credit
          update = true
        }

        if (this.event_detail_data.description != this.backup_event_detail_data.description) {
          send_data.description = this.event_detail_data.description
          update = true
        }

        send_data.splash = this.cover     

        if (!update && !this.image_update) {
          this.alertBox.text = '沒有資料需要更新'
          this.alertBox.type = 'error'
          this.alertBox.show = true
          setTimeout(() => {
            this.submitLoading = false
            this.alertBox.show = false
          }, 1500)
          return
        }
      }

      try {
        let params = new URLSearchParams()
        params.set(key, JSON.stringify(send_data))
        let result = await postData(params)
        console.log("--back-data--")
        console.log(result)


        let send_data_delete = {}
        console.log("--this.delete_album_list--")
        console.log(this.delete_album_list)
        for(let i=0; i<this.delete_album_list.length;i++) {
          send_data_delete.id = this.delete_album_list[i]
          send_data_delete.active = false

          let params3 = new URLSearchParams()
          params3.set("update_album", JSON.stringify(send_data_delete))
          let result3 = await postData(params3)
        }


        let key_album = ''
        console.log('--update album_list--')
        console.log(this.album_list)
        for(let i=0; i<this.album_list.length; i++) {
          send_data_album = {}
          if(this.album_list[i].update) {
            key_album = 'update_album'
            send_data_album.id = this.album_list[i].id
            send_data_album.event_id = parseInt(this.$route.params.id)
          } else {
            key_album = 'new_album'
            send_data_album.event_id = result.id
          }
          
          send_data_album.images = this.album_list[i].images
          send_data_album.name = "Album Test"
          
          console.log(key_album)
          console.log(send_data_album)
          let params2 = new URLSearchParams()
          params2.set(key_album, JSON.stringify(send_data_album))
          let result2 = await postData(params2)
          console.log('--get album by event id--'+this.album_list[i].id)
          console.log(result2)
        }

        

        if (!Array.isArray(result.data)) {
          this.alertBox.text = '儲存成功'
          this.alertBox.type = 'success'
          this.alertBox.show = true
          setTimeout(() => {
            this.$router.replace({ name: 'event-management' })
          }, 1500)
        } else {
          this.alertBox.text = '沒有資料需要更新'
          this.alertBox.type = 'error'
          this.alertBox.show = true
        }
      } catch (error) {
        console.log(error)

        if (error == 'share_url exist') {
          this.alertBox.text = '分享連結重覆'
        } else {
          this.alertBox.text = '儲存失敗'
        }
        this.alertBox.type = 'error'
        this.alertBox.show = true
      } finally {
        setTimeout(() => {
          this.submitLoading = false
          this.alertBox.show = false
        }, 1500)
      }
    },
    
    /**
     * Kick out user
    */
    kickout() {
      console.log('--- Validation Fail ---')
      this.alertBox.text = '登入憑證過期，3秒後重新導向'
      this.alertBox.type = 'error'
      this.alertBox.show = true

      setTimeout(() => {
        this.alertBox.show = false
        let send_data = []
        send_data['method'] = 'resetData'
        this.reset(send_data)
        this.$router.push({ name: 'Login' })
      }, 3000)
    },
    saveTime() {
      this.meeting_time = `${this.hour}:${this.min}`
      this.$refs.meeting_time_menu.save(this.meeting_time)
    },
    saveTimeEnd() {
      this.meeting_time_end = `${this.hour_end}:${this.min_end}`
      this.$refs.meeting_time_menu_end.save(this.meeting_time_end)
    },
  },
  /**
   * Initialize everything at beginning
   * @constructor
   */
  created() {
    setTimeout(async () => {
      this.initializeAlbum();
      console.log('--- Validation ---')
      if (globalFunc.DataValid(this.$store.state.api.login_data)) {

        try {
          this.isAddEvent = true
          this.showLoadingPage = true
          setTimeout(() => {
            this.showLoadingPage = false
          }, 1000)

          await this.getPerformerData()
          if (globalFunc.DataValid(this.$route.params.id)) {
            this.isAddEvent = false
            this.initializeData()
          }
        } catch (error) {
          console.log('--- Check User Permission Fail ---')
          console.log(error)
          this.verifiedError = true
          this.showLoadingPage = false
          this.alertBox.text = '資料取得失敗'
          this.alertBox.type = 'error'
          this.alertBox.show = true

          setTimeout(() => {
            this.alertBox.show = false
            this.$router.replace({ name: 'event' })
          }, 3000)
        }
      } else {
        this.kickout()
      }
    }, 200)
  },
}
</script>


<style lang="scss" scoped>
@import '~vue2-timepicker/dist/VueTimepicker.css';
</style>
<template>
  <v-container fluid class="pa-0 ma-0">
    <v-tooltip top v-model="showtip" :nudge-left="offset" :open-on-hover="false" max-width="220">
      <template v-slot:activator="{ on }">
        <vue-editor
          retain-focus-on-click
          @blur.native="on.blur"
          :id="editorID"
          ref="editor"
          useCustomImageHandler
          @image-added="addEditorImage"
          @text-change="onTextChange"
          :editorOptions="getEditorOptions"
          v-model="html"
        ></vue-editor>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>

    <v-alert
      class="mt-3"
      v-show="msgImgFormat"
      :icon="mdiAlertCircleOutline"
      rounded
      dense
      type="error"
      transition="scroll-y-transition"
    >
      <span class="text-subtitle-2">{{ 'uploadWrongImgFormat' }}</span>
    </v-alert>
    <v-alert
      class="mt-3"
      v-show="msgImgSize"
      :icon="mdiAlertCircleOutline"
      rounded
      dense
      type="error"
      transition="scroll-y-transition"
    >
      <span class="text-subtitle-2">{{ 'uploadTooBig8M' }}</span>
    </v-alert>
    <v-alert
      class="mt-3"
      v-show="msgImgFail"
      :icon="mdiAlertCircleOutline"
      rounded
      dense
      type="error"
      transition="scroll-y-transition"
    >
      <span class="text-subtitle-2">{{ 'uploadImgFailed' }}</span>
    </v-alert>

    <v-alert
      class="mt-3"
      v-show="uploading"
      :icon="mdiInformationOutline"
      rounded
      dense
      type="success"
      transition="scroll-y-transition"
    >
      <span class="text-subtitle-2">{{ 'uploadingFileInfo' + ' (' + progress + '%)' }}</span>
    </v-alert>

    <v-alert
      class="mt-3"
      v-show="msgTextLimit"
      :icon="mdiAlertOutline"
      rounded
      dense
      type="warning"
      transition="scroll-y-transition"
    >
      <span class="text-subtitle-2">{{ 'editorLimit' }}</span>
    </v-alert>
    <v-alert
      class="mt-3"
      v-show="msgUrlInvalid"
      :icon="mdiAlertOutline"
      rounded
      dense
      type="warning"
      transition="scroll-y-transition"
    >
      <span class="text-subtitle-2">{{ 'editorURLinvalid' }}</span>
    </v-alert>
  </v-container>
</template>

<script>
import { mdiAlertCircleOutline } from '@mdi/js'
import { mdiAlertOutline } from '@mdi/js'
import { mdiInformationOutline } from '@mdi/js'
import { mdiClose } from '@mdi/js'

import { VueEditor } from 'vue2-editor/dist/vue2-editor.core.js'
import { Quill } from 'vue2-editor' // Hook into Quill's API for Custom Functionality
import ImageResize from 'quill-image-resize'
import { MEDIA_PATH, globalFunc } from '@/global'
import { postData } from '@/api'
// :attach="tipParent"

Quill.register('modules/imageResize', ImageResize)

export default {
  name: 'QuillEditor',

  props: {
    html: String,
    editorID: String,
  },

  components: { VueEditor },

  computed: {
    getEditorOptions: function () {
      let options = this.editorSettings
      options['bounds'] = '#' + this.editorID
      return options
    },
  },

  created() {
    // this.devlog(this.$ref);
    let icons = Quill.import('ui/icons')
    icons['undo'] =
      '<svg viewBox="0 0 24 24"><path class="ql-fill" d="M20 13.5C20 17.09 17.09 20 13.5 20H6V18H13.5C16 18 18 16 18 13.5S16 9 13.5 9H7.83L10.91 12.09L9.5 13.5L4 8L9.5 2.5L10.92 3.91L7.83 7H13.5C17.09 7 20 9.91 20 13.5Z" /></svg>'
    icons['redo'] =
      '<svg viewBox="0 0 24 24"><path class="ql-fill" d="M10.5 18H18V20H10.5C6.91 20 4 17.09 4 13.5S6.91 7 10.5 7H16.17L13.08 3.91L14.5 2.5L20 8L14.5 13.5L13.09 12.09L16.17 9H10.5C8 9 6 11 6 13.5S8 18 10.5 18Z" /></svg>'
  },

  mounted() {
    this.setToolbar()
    this.setFormatter()
    this.setPlaceholder()
    // this.setSanitizer();
    this.tipParent = document.querySelector('#' + this.editorID)
  },

  data: () => ({
    editorSettings: {
      modules: {
        toolbar: {
          container: [
            [{ undo: 'undo' }, { redo: 'redo' }], // custom redo/undo button
            [{ header: [1, 2, 3, 4, false] }],
            ['bold', 'italic', 'underline', 'strike', { script: 'sub' }, { script: 'super' }],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ list: 'bullet' }, { list: 'ordered' }, 'blockquote', 'code-block'],
            // ['link', 'image', 'video', 'formula'],
            ['link', 'image'],
            ['clean'],
          ],
          handlers: {
            redo() {
              this.quill.history.redo()
            },
            undo() {
              this.quill.history.undo()
            },
          },
        },
        history: {
          delay: 2000,
          maxStack: 100,
          userOnly: true,
        },
        imageResize: {
          modules: ['Resize', 'DisplaySize'],
        },
      },
      theme: 'snow',
    },
    showtip: false,
    tipParent: null,
    tipCount: 0,
    tooltipText: '',
    offset: 0,
    progress: 0,
    uploading: false,

    msgImgFormat: false,
    msgImgSize: false,
    msgImgFail: false,
    msgTextLimit: false,
    msgUrlInvalid: false,

    mdiAlertCircleOutline: mdiAlertCircleOutline,
    mdiInformationOutline: mdiInformationOutline,
    mdiClose: mdiClose,
    mdiAlertOutline: mdiAlertOutline,
  }),

  watch: {
    // tipCount: function (val) {
    //     this.showtip = val > 0;
    // },
  },

  methods: {
    setSanitizer() {
      // validate and sanitize URL
      const vm = this
      let save = this.$refs.editor.quill.theme.tooltip.save
      this.$refs.editor.quill.theme.tooltip.save = function () {
        // overwrite save link functionality

        var url = this.textbox.value
        const mode = this.textbox.parentNode.getAttribute('data-mode')

        // force HTTPS
        if (url.indexOf('http') != 0) {
          url = 'https://' + url
        } else if (url.indexOf('http://') == 0) {
          url = 'https://' + url.substring(7)
        }

        // validate url
        if (mode == 'video' ? vm.RegExTester.urlYoutube(url) : vm.RegExTester.urlFull(url)) {
          this.textbox.value = url
          save.call(this)
        } else {
          // show error in tooltip
          vm.msgUrlInvalid = true
          setTimeout(() => {
            vm.msgUrlInvalid = false
          }, 5000)
        }
      }
    },
    // setFormatter() { // cleaning text formatting only
    //     this.$refs.editor.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
    //         delta.ops = delta.ops.map(op => {
    //             return {
    //                 insert: op.insert
    //             };
    //         });
    //         return delta;
    //     })
    // },
    setFormatter() {
      // get rid of everything (images, iframes, etc.) but plain text
      this.$refs.editor.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = []
        delta.ops.forEach(op => {
          if (op.insert && typeof op.insert === 'string') {
            ops.push({ insert: op.insert })
          }
        })
        delta.ops = ops
        return delta
      })
    },
    setPlaceholder() {
      let input = document.querySelector('input[data-link]')
      input.dataset.link = 'https://www.google.com'
      input.dataset.video = 'https://youtu.be/lepYkDZ62OY'
      input.placeholder = 'https://www.google.com'
    },
    setToolbar() {
      const titleConfig = {
        'ql-bold': 'bold',
        'ql-color': 'color',
        'ql-font': 'font',
        'ql-code': 'code',
        'ql-italic': 'italic',
        'ql-link': 'link',
        'ql-background': 'background',
        'ql-size': 'size',
        'ql-strike': 'strike',
        'ql-script': 'script',
        'ql-underline': 'underline',
        'ql-blockquote': 'blockquote',
        'ql-header': 'header',
        'ql-indent': 'indent',
        'ql-list': 'list',
        'ql-align': 'align-l',
        'ql-direction': 'direction',
        'ql-code-block': 'code-block',
        'ql-formula': 'formula',
        'ql-image': 'ql-image',
        // "ql-video": "ql-video",
        'ql-clean': 'clean',
        'ql-undo': 'undo',
        'ql-redo': 'redo',
      }
      const oToolBar = document.querySelector('.ql-toolbar')
      const aButton = oToolBar.querySelectorAll('button')
      const aSelect = oToolBar.querySelectorAll('select')
      const vm = this

      // aButton.forEach(function (item) {
      //   let setVal = ''
      //   if (item.className === 'ql-script' && item.value != '') {
      //     setVal = item.value === 'sub' ? 'script-sub' : 'script-sup'
      //   } else if (item.className === 'ql-indent' && item.value != '') {
      //     setVal = item.value === '+1' ? 'indent-r' : 'indent-l'
      //   } else if (item.className === 'ql-list' && item.value != '') {
      //     setVal = item.value === 'bullet' ? 'list-bullet' : 'list-order'
      //   } else if (item.className === 'ql-align' && item.value != '') {
      //     switch (item.value) {
      //       case 'center':
      //         setVal = 'align-c'
      //         break
      //       case 'right':
      //         setVal = 'align-r'
      //         break
      //       case 'justify':
      //         setVal = 'align-j'
      //         break
      //       default:
      //         setVal = 'align'
      //     }
      //   } else {
      //     setVal = titleConfig[item.classList[0]]
      //   }
      //   item.setAttribute('data-editor-tooltip', setVal)
      //   item.addEventListener('mouseleave', function () {
      //     // ev.preventDefault();
      //     if (vm.tipCount > 0) vm.tipCount -= 1
      //     vm.showtip = vm.tipCount > 0
      //     // vm.devlog('leave:' + vm.tipCount);
      //   })
      //   item.addEventListener('mouseenter', function (ev) {
      //     // ev.preventDefault();
      //     vm.tooltipText = setVal
      //     // vm.tipParent = item;
      //     setTimeout(() => {
      //       if (vm.tipCount <= 0) vm.tipCount += 1
      //       let rect = oToolBar.getBoundingClientRect()
      //       vm.offset = rect.right - rect.width / 2 - ev.clientX
      //       vm.showtip = vm.tipCount > 0
      //       // vm.devlog('enter:' + vm.tipCount + ':' + ev.clientX);
      //     }, 100)
      //   })
      //   // item.addEventListener('mouseover', function(){
      //   //     // ev.preventDefault();
      //   //     if (vm.tipCount <= 0) vm.tipCount = 1;
      //   //     vm.showtip = vm.tipCount > 0;
      //   //     vm.devlog('over:' + vm.tipCount);
      //   // });
      // })
      // aSelect.forEach(function (item) {
      //   // item.parentNode.title = titleConfig[item.classList[0]];
      //   item.parentNode.setAttribute('data-editor-tooltip', titleConfig[item.classList[0]])
      // })
      // oToolBar.addEventListener('mouseleave', function () {
      //   vm.tipCount = 0
      //   vm.showtip = vm.tipCount > 0
      //   setTimeout(() => {
      //     vm.tipCount = 0
      //     vm.showtip = vm.tipCount > 0
      //   }, 200)
      // })
    },
    uploadImgExtCheck(name, mimeType, allowed) {
      const fileExt = name.split('.').pop().toLowerCase()
      const fileMime = mimeType.substring(6).toLowerCase() // remove 'image/' from MIME type
      return allowed.indexOf(fileExt) >= 0 && allowed.indexOf(fileMime) >= 0
    },
    failedUpload(result, data) {
      const vm = this
      // vm.devlog(result, data);
      vm.uploading = false
      vm.progress = 0
      vm.msgImgFail = true
      setTimeout(() => {
        vm.msgImgFail = false
      }, 5000)
    },
    addEditorImage: async function (file, Editor, cursorLocation, resetUploader) {
      const vm = this
      if (file) {
        if (vm.uploadImgExtCheck(file.name.split('.').pop(), file.type, ['jpg', 'jpeg', 'png'])) {
          if (file.size > 0 && file.size < 1024 * 1024 * 8) {
            vm.uploading = true
            vm.progress = 0

            try {
              let bas4Path = await globalFunc.firstFileToBase64(file)

              let type = file.type.substring(file.type.lastIndexOf('/') + 1)
              let params = new URLSearchParams()
              let send_data = {
                upload_data: bas4Path,
                upload_file_type: type,
              }

              params.set('upload_file', JSON.stringify(send_data))

              const config = {
                onUploadProgress: progressEvent => {
                  console.log(progressEvent.loaded)
                  vm.progress = progressEvent.loaded * 0.75
                },
              }

              let upload_image = await postData(params, config)
              vm.uploading = false
              vm.progress = 100
              Editor.insertEmbed(cursorLocation, 'image', MEDIA_PATH + upload_image)
              resetUploader()
            } catch (error) {
              console.log(error)
              vm.uploading = false
              resetUploader()
            }
          }
        } else {
          vm.msgImgFormat = true
          setTimeout(() => {
            vm.msgImgFormat = false
          }, 5000)
        }
      }
    },
    onTextChange(delta, old, source) {
      // this.devlog(delta, old, source);
      let quill = this.$refs.editor.quill
      if (quill.getLength() < 0) {
        // plus 12 for overflow
        this.msgTextLimit = true
        quill.deleteText(1612, quill.getLength())
      } else {
        this.msgTextLimit = false
      }
      // this.model = quill;
      // console.log(this.html);
      this.$emit('update:html', this.html)
    },
  },
}
</script>

<style>
@import '~vue2-editor/dist/vue2-editor.css';

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: '標題1' !important;
}
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: '標題2' !important;
}
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: '標題3' !important;
}
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: '標題4' !important;
}
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: '標題5' !important;
}
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: '標題6' !important;
}
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-label::before,
:lang(zh-HK) .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '正文' !important;
}

:lang(zh-HK) .ql-snow .ql-tooltip::before {
  content: '瀏覽URL:' !important;
}
.ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'URL (with HTTPS):' !important;
}
:lang(zh-HK) .ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'URL (需支援HTTPS):' !important;
}
:lang(zh-HK) .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: '儲存' !important;
  padding-right: 0px;
}
:lang(zh-HK) .ql-snow .ql-tooltip a.ql-action::after {
  content: '編輯';
}
:lang(zh-HK) .ql-snow .ql-tooltip a.ql-remove::before {
  content: '移除';
}
.ql-snow .ql-tooltip[data-mode='video']::before {
  content: 'YouTube Video URL:' !important;
}
:lang(zh-HK) .ql-snow .ql-tooltip[data-mode='video']::before {
  content: 'YouTube影片網址:' !important;
}
:lang(zh-HK) .ql-snow .ql-tooltip[data-mode='formula']::before {
  content: '請輸入公式:' !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #acd1aa;
}
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #acd1aa;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #acd1aa;
}
.ql-snow .ql-tooltip {
  border-radius: 0.5rem;
}
body.dark .ql-snow .ql-tooltip {
  background-color: #000;
  color: #fff;
}
body.dark .ql-snow input {
  color: #fff;
}
body.dark .ql-snow ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #aaa;
}
body.dark .ql-snow ::-moz-placeholder {
  /* Firefox 19+ */
  color: #aaa;
}
body.dark .ql-snow :-ms-input-placeholder {
  /* IE 10+ */
  color: #aaa;
}
body.dark .ql-snow :-moz-placeholder {
  /* Firefox 18- */
  color: #aaa;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-right: 12px;
  padding-bottom: 2px;
  border-right: 1px solid #999;
}
body.dark .ql-toolbar.ql-snow .ql-formats {
  border-color: #aaa;
}

.ql-editor {
  overflow: visible;
}
.quillWrapper .ql-snow.ql-toolbar {
  border-radius: 0.5rem 0.5rem 0 0;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0 0 0.5rem 0.5rem;
}
.ql-snow .ql-editor pre.ql-syntax {
  background-color: #666;
}

body.dark .ql-snow .ql-picker {
  color: #ddd;
}
body.dark .ql-snow .ql-picker-options {
  background-color: #000;
}

body.dark .quillWrapper .ql-snow .ql-stroke {
  stroke: rgba(242, 242, 242, 0.9);
}
body.dark .quillWrapper .ql-snow .ql-fill {
  fill: rgba(242, 242, 242, 0.9);
}
</style>